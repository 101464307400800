<script>
	import { deleteDoc, doc, onSnapshot, updateDoc } from "firebase/firestore";
	import { repgen } from "@/firebase"
	import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
	import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'
	import SlackService from "@/services/SlackService"
	export default {
		components: {
			CheckIcon,
			SelectorIcon,
			Listbox,
			ListboxButton,
			ListboxOption,
			ListboxOptions
		},
		data() {
			return {
				email: null,
				name: "",
				nickName: "",
				nickNameTemp: "",
				role: "",
				microsoftLinked: false,
				slackLinked: false,
				uid: this.$route.params.userId,
				editNick: false,
				roles: [
					"Managing Partner",
					"Partner",
					"Associate Partner",
					"Consultant"
				]
			};
		},
		created() {},
		mounted() {
			onSnapshot(doc(repgen, "employees", this.uid), (snapshot) => {
				let data = snapshot.data()
				this.email = data.email
				this.name = data.name
				this.nickName = data.nickName
				this.nickNameTemp = this.nickName
				this.role = data.role
			})

			onSnapshot(doc(repgen, "employees", this.uid, "integrations", "microsoft"), (data) => {
				if(data.exists()) {
					this.microsoftLinked = data.data().linked
				} else {
					this.microsoftLinked = false
				}
			})

			onSnapshot(doc(repgen, "employees", this.uid, "integrations", "slack"), (data) => {
				if(data.exists()) {
					this.slackLinked = data.data().linked
				} else {
					this.slackLinked = false
				}
			})
		},
		methods: {

			linkSlack() {
				SlackService.startLink()
			},
			unlinkSlack() {
				deleteDoc(doc(repgen, "employees", this.uid, "integrations", "slack"))
			},
			saveNick() {
				updateDoc(doc(repgen, "employees", this.uid), {
					nickName: this.nickNameTemp
				})
				this.editNick = false
			},

			saveRole() {
				updateDoc(doc(repgen, "employees", this.uid), {
					role: this.role
				})
			},
		}
	};
</script>

<template>
		
		
	<div class="bg-white shadow overflow-hidden sm:rounded-lg">
		<div class="px-4 py-5 sm:px-6">
			<div class="flex justify-between">
				<h1 class="text-6xl font-semibold text-blue-700 dark:text-blue-500">
					User Information
				</h1>
			</div>
		</div>
		<div class="border-t border-gray-200 divide">
			<dl class="divide-y">
				<div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
					<dt class="text-sm font-medium text-gray-500">Full name</dt>
					<dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{ name }}</dd>
				</div>
				<div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
					<dt class="text-sm font-medium text-gray-500">Email address</dt>
					<dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{ email }}</dd>
				</div>
				<div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
					<dt class="text-sm font-medium text-gray-500">Role</dt>
					<Listbox as="div" v-model="role" @update:model-value="saveRole()">
						<div class="mt-1 relative">
							<ListboxButton class="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
								<span class="flex items-center">
									<span class="ml-3 block truncate">{{ role }}</span>
								</span>
								<span class="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
									<SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
								</span>
							</ListboxButton>

							<transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
								<ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
									<ListboxOption as="template" v-for="r in roles" :key="r" :value="r" v-slot="{ active, selected }">
										<li :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
											<div class="flex items-center">
												<span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">
													{{ r }}
												</span>
											</div>

											<span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
												<CheckIcon class="h-5 w-5" aria-hidden="true" />
											</span>
										</li>
									</ListboxOption>
								</ListboxOptions>
							</transition>
						</div>
					</Listbox>
				</div>
				<div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-12 sm:gap-4 sm:px-6">
					<dt class="mt-1 text-sm font-medium text-gray-500 sm:col-span-4">Display Name</dt>
					<dd v-if="!editNick" class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-6">{{ nickName }}</dd>
					<dd v-else class="text-sm text-gray-900 sm:mt-0 sm:col-span-6">
						<input type="text" id="nickName" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-3 sm:text-sm border-gray-300 rounded-md" v-model="nickNameTemp">
					</dd>
					<button v-if="editNick" v-on:click="saveNick()" class="font-medium text-blue-800">
						Save
					</button>
					<div v-else>
						<button v-on:click="editNick = true" class="font-medium text-blue-800">
							Edit
						</button>
					</div>
					<div v-if="editNick" class="mt-2">
						<button v-on:click="editNick = false; nickNameTemp = nickName" class="font-medium text-blue-800">
							Cancel
						</button>
					</div>
					
				</div>
				<div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-6 sm:gap-4 sm:px-6">
					<dt class="text-sm font-medium text-gray-500 sm:col-span-2">Slack Account</dt>
					<dd v-if="slackLinked" class="mt-1 text-sm sm:mt-0 sm:col-span-3 flex text-green-500">
						<CheckIcon class="flex-shrink-0 h-5 w-5" aria-hidden="true" />
						<span class="flex-1 w-0 truncate">Linked</span>
					</dd>
					<div v-if="slackLinked">
						<button class="font-medium text-blue-800" v-on:click="unlinkSlack()">
							Unlink
						</button>
					</div>
					<dd v-else class="mt-1 text-sm sm:mt-0 sm:col-span-4 flex">
						<button v-on:click="linkSlack()" style="align-items:center;color:#000;background-color:#fff;border:1px solid #ddd;border-radius:4px;display:inline-flex;font-family:Lato, sans-serif;font-size:14px;font-weight:600;height:44px;justify-content:center;text-decoration:none;width:224px">
							<svg xmlns="http://www.w3.org/2000/svg" style="height:16px;width:16px;margin-right:12px" viewBox="0 0 122.8 122.8"><path d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z" fill="#e01e5a"></path><path d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z" fill="#36c5f0"></path><path d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z" fill="#2eb67d"></path><path d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z" fill="#ecb22e"></path></svg>
							Sign in with Slack
						</button>
					</dd>
				</div>
			</dl>
		</div>
	</div>
</template>

<style></style>